import React from "react"
import { Link } from "gatsby"
import styled, { css, keyframes } from "styled-components"

import { rhythm, scale } from "../utils/typography"
import "../fonts/fonts.css"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <Jtitle
          style={{
            ...scale(1),
            marginBottom: rhythm(1.5),
            marginTop: 0,
            fontFamily: `Happy`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={location.pathname === blogPath ? `/blog/` : `/`}
          >
          {title}
          </Link>
        </Jtitle>
      )
    } else {
      header = (
        <h2
          style={{
            fontFamily: `Happy`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog/`}
          >
            {title}
          </Link>
        </h2>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(25),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">
            <span
              css={css`
                margin-right: 0.2rem;
                display: inline-block;
                vertical-align: middle;
              `}
            >
              <svg width="16" height="16" viewBox="0 0 512.000000 512.000000">
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2225 5109 c-980 -126 -1810 -821 -2113 -1771 -85 -265 -112 -453
-112 -778 0 -325 27 -513 112 -778 305 -956 1138 -1650 2128 -1772 109 -13
531 -13 640 0 990 122 1823 816 2128 1772 85 265 112 453 112 778 0 325 -27
513 -112 778 -305 956 -1138 1650 -2128 1772 -100 12 -555 11 -655 -1z m597
-554 c398 -53 776 -227 1077 -496 102 -92 281 -292 281 -315 0 -12 -258 -234
-271 -234 -4 0 -22 19 -40 43 -249 320 -586 533 -983 619 -87 19 -131 22 -326
22 -195 0 -239 -3 -326 -22 -549 -119 -989 -488 -1198 -1002 l-26 -65 1048
-1047 1047 -1048 65 26 c376 152 680 434 864 803 42 83 103 247 120 324 l6 27
-435 0 -435 0 0 185 0 185 640 0 640 0 0 -74 c0 -174 -52 -435 -125 -631 -219
-588 -721 -1052 -1324 -1226 l-113 -32 -1205 1206 -1206 1205 32 113 c218 756
878 1327 1656 1433 137 19 399 19 537 1z m-302 -4003 c-6 -2 -65 1 -133 7
-979 90 -1728 831 -1827 1810 -5 57 -10 117 -10 135 0 28 83 -52 990 -959 545
-545 985 -992 980 -993z"
                  />
                </g>
              </svg>
            </span>
            Gatsby
          </a>
        </Footer>
      </Wrapper>
    )
  }
}

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

const animation = css`
  ${bounce} 1s ease 1;
`

const Jtitle = styled.h1`
  margin-bottem: ${rhythm(2)};
  display: inline-block;
  font-style: normal;
  font-family: Happy;
  &:hover {
    animation: ${animation};
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
